import React, { useState } from "react";
import { Link } from "react-router-dom";
import Fade from "react-reveal/Fade";
import { FaTimes } from "react-icons/fa";
import { GiHamburgerMenu } from "react-icons/gi";

import logo from "../assets/logo1.png";
import logoText from "../assets/logo-text.png";

interface Route {
  path: string;
  name: string;
}

interface NavbarProps {
  routes: Route[];
}

export const Navbar: React.FC<NavbarProps> = ({ routes }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeRoute, setActiveRoute] = useState("");

  const toggleIsOpen = () => {
    setIsOpen(!isOpen);
  };

  const closeOverlay = () => {
    setIsOpen(false);
  };

  const renderLinks = () =>
    routes
      .filter(
        (route) =>
          !["resident", "mix", "home", "type", "item", "event"].includes(
            route.name
          )
      )
      .map((route, index) => (
        <Fade>
          <Link
            to={route.path}
            key={index}
            onClick={() => {
              closeOverlay();
              setActiveRoute(route.name);
            }}
            className={route.name === activeRoute ? "active-link" : ""}
          >
            {route.name}
          </Link>
        </Fade>
      ));

  return (
    <Fade>
      <div className="navbar">
        <div className="navbar-contents">
          <div className="navbar-left">
            <div className="navbar-logo">
              <Link
                to="/"
                onClick={() => {
                  setActiveRoute("");
                }}
              >
                <img src={logo} alt="Live At The BBQ" />
              </Link>
            </div>
          </div>

          <div className="navbar-middle">
            <Link
              to="/"
              onClick={() => {
                setActiveRoute("");
              }}
            >
              <img src={logoText} alt="logo-text" />
            </Link>
          </div>

          <div className="navbar-right">
            <div className={isOpen ? "navbar-links-overlay" : "navbar-links"}>
              {renderLinks()}
            </div>
            <div className="navbar-icons" onClick={toggleIsOpen}>
              {isOpen ? (
                <FaTimes className="icon" />
              ) : (
                <GiHamburgerMenu className="icon" />
              )}
            </div>
          </div>
        </div>
      </div>
    </Fade>
  );
};
