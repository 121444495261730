import { useDispatch } from "react-redux";
import { useQuery } from "@apollo/client";
import { media } from "../gql/Query";
import { setMixes } from "../store/reducers/mediaReducer";
import homecolor from "../assets/homeseries-color.png";
import radiocolor from "../assets/radio-color.png";
import Fade from "react-reveal/Fade";
import { MixItem } from "../components/MixItem";

export const Mixes = () => {
  const { data } = useQuery(media);
  const mixes = data?.mixes || [];
  const dispatch = useDispatch();
  dispatch(setMixes(mixes));

  return (
    <Fade>
      <div className="mixes">
        <div className="mix-row">
          <img src={radiocolor} alt="radio series" className="radio" />
          <div className="mix-items">
            {mixes?.map(
              (mix: any, index: number) =>
                mix.category === "RTNP" && (
                  <MixItem
                    key={index}
                    title={mix.title}
                    cardIndex={index}
                    audioUrl={mix.audio.url}
                    url={`/mixes/${mix.slug}`}
                    date={mix.date}
                    residentName={mix.residentName}
                    genre={mix.genre}
                    videoUrl={mix.video && mix.video.url ? mix.video.url : ""}
                    videoStill={
                      mix.videoStill && mix.videoStill.url
                        ? mix.videoStill.url
                        : ""
                    }
                  />
                )
            )}
          </div>
        </div>

        <div className="mix-row">
          <img src={homecolor} alt="home series" className="home" />
          <div className="mix-items">
            {mixes?.map(
              (mix: any, index: number) =>
                mix.category === "Home Mixes" && (
                  <MixItem
                    key={index}
                    title={mix.title}
                    cardIndex={index}
                    audioUrl={mix.audio.url}
                    url={`/mixes/${mix.slug}`}
                    date={mix.date}
                    genre={mix.genre}
                    residentName={mix.residentName}
                    videoUrl={mix.video && mix.video.url ? mix.video.url : ""}
                    videoStill={
                      mix.videoStill && mix.videoStill.url
                        ? mix.videoStill.url
                        : ""
                    }
                  />
                )
            )}
          </div>
        </div>
      </div>
    </Fade>
  );
};

export default Mixes;
