import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Fade from "react-reveal/Fade";

import { BsPlayFill, BsPauseFill } from "react-icons/bs";
import { toggleIsPlaying } from "../store/reducers/mediaReducer";

export const MusicPlayer = () => {
  const { audioTitle, audioSource, isPlaying } = useSelector(
    (state: any) => state.media
  );
  const dispatch = useDispatch();

  const audioRef = useRef<HTMLAudioElement | null>(null);

  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [isSeeking, setIsSeeking] = useState(false);
  const [volumeControl, setVolumeControl] = useState(50);

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.addEventListener("loadedmetadata", handleLoadedMetadata);
      audioRef.current.addEventListener("timeupdate", handleTimeUpdate);
      audioRef.current.addEventListener("ended", handleEnded);
    }
    return () => {
      if (audioRef?.current) {
        audioRef.current.removeEventListener(
          "loadedmetadata",
          handleLoadedMetadata
        );
        audioRef.current.removeEventListener("timeupdate", handleTimeUpdate);
        audioRef.current.removeEventListener("ended", handleEnded);
      }
    };
  }, [audioSource]);

  useEffect(() => {
    if (audioRef?.current) {
      if (isPlaying) {
        audioRef?.current.play();
      } else {
        audioRef?.current.pause();
      }
    }
  }, [isPlaying, audioRef]);

  const handleLoadedMetadata = () => {
    setDuration(audioRef?.current ? audioRef?.current.duration : 0);
  };

  const handleTimeUpdate = () => {
    setCurrentTime(audioRef?.current ? audioRef?.current.currentTime : 0);
  };

  const handleEnded = () => {
    dispatch(toggleIsPlaying());
    setCurrentTime(0);
  };

  const togglePlayback = () => {
    dispatch(toggleIsPlaying());
  };

  const handleSeek = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (audioRef.current && !isSeeking) {
      const seekTime = parseFloat(e.target.value);
      audioRef.current.currentTime = seekTime;
      setCurrentTime(seekTime);
    }
  };

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };

  const setVolume = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newVolume = parseFloat(e.target.value);
    setVolumeControl(newVolume);

    if (audioRef.current) {
      audioRef.current.volume = newVolume / 100;
    }
  };

  if (!audioSource) {
    return null;
  }

  return (
    <Fade>
      <div className="music-player-container">
        <div className="content">
          <div className="audio-player">
            <audio ref={audioRef} src={audioSource} />
          </div>

          {/*Title*/}
          <div className="audio-title">
            <p>{audioTitle}</p>
          </div>

          {/*Buttons*/}
          <div className="buttons">
            {isPlaying ? (
              <BsPauseFill
                className="play-pause-button"
                onClick={togglePlayback}
              />
            ) : (
              <BsPlayFill
                className="play-pause-button"
                onClick={togglePlayback}
              />
            )}
          </div>

          <div className="progress">
            {/*Current Time*/}
            <div className="time-duration">
              <p>{formatTime(currentTime)}</p>
            </div>

            {/*Progress Bar*/}
            <input
              className="progress-bar"
              onChange={handleSeek}
              type="range"
              min={0}
              max={duration}
              value={currentTime}
            />

            {/*Remaining Time*/}
            <div className="time-duration">
              <p>{formatTime(duration)}</p>
            </div>
          </div>

          {/*Volume*/}
          <div className="volume">
            <p>VOL</p>
            <input
              className="volume-control-bar"
              onChange={setVolume}
              type="range"
              min={0}
              max={100}
            />
          </div>
        </div>
      </div>
    </Fade>
  );
};
