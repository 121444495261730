import { useQuery } from "@apollo/client";
import { media } from "../gql/Query";

import { GridItem } from "../components/GridItem";
import Fade from "react-reveal/Fade";

export const Library = () => {
  const { data } = useQuery(media);
  console.log(data);
  const libraryGrid = data?.libraryGrids;
  console.log(libraryGrid);
  console.log(libraryGrid);

  const findImageUrl = (name: string) => {
    const matchingGridItem = libraryGrid?.find(
      (grid: any) => grid.title === name
    );
    return matchingGridItem ? matchingGridItem.image.url : null;
  };

  const gridItems = [
    { path: "read", name: "read" },
    { path: "listen", name: "listen" },
    { path: "watch", name: "watch" },
  ];
  console.log(gridItems);

  return (
    <Fade>
      <div className="content-container library">
        <p className="library-text">
          Explore our library—a grassroots effort driven by our community
          members. Delve into a diverse collection of books, movies, and
          podcasts. If you're interested in contributing, feel free to get in
          touch with us.
        </p>
        <div className="grid">
          {libraryGrid?.map((item: any, index: number) => (
            <GridItem
              key={index}
              url={`/library/${item.title}`}
              imageUrl={item?.image?.url}
              titleImage={item?.titleImage?.url}
            />
          ))}
        </div>
      </div>
    </Fade>
  );
};

export default Library;
