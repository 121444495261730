import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { media } from "./gql/Query";

// Components
import { Navbar } from "./components/Navbar";
import { MusicPlayer } from "./components/MusicPlayer";
import { Loading } from "./components/Loading";
import ScrollToTopOnRouteChange from "./components/ScrollToTop";
import { Error } from "./components/Error";

// Pages
import Home from "./pages/Home";
import About from "./pages/About";
import Residents from "./pages/Residents";
import Resident from "./pages/Resident";
import Mixes from "./pages/Mixes";
import Contact from "./pages/Contact";
import Mix from "./pages/Mix";
import Library from "./pages/Library";
import LibraryInner from "./pages/LibraryInner";
import LibraryItem from "./pages/LibraryItem";
import Events from "./pages/Events";
import Event from "./pages/Event";

// Styling & Assets
import "../src/styling/_app.scss";

const routes = [
  { path: "/", name: "home", element: <Home /> },
  { path: "/about", name: "about", element: <About /> },
  { path: "/family", name: "family", element: <Residents /> },
  { path: "/events", name: "events", element: <Events /> },
  { path: "/events/:name", name: "event", element: <Event /> },
  { path: "/library", name: "library", element: <Library /> },
  { path: "/mixes", name: "mixes", element: <Mixes /> },
  { path: "/library/:name", name: "type", element: <LibraryInner /> },
  { path: "/library/:name/:item", name: "item", element: <LibraryItem /> },
  { path: "/residents/:name", name: "resident", element: <Resident /> },
  { path: "/contact", name: "contact", element: <Contact /> },
  { path: "/mixes/:slug", name: "mix", element: <Mix /> },
];

export const App = () => {
  const { error, loading } = useQuery(media);
  const [showLoading, setShowLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLoading(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  if (showLoading || loading) {
    return <Loading />;
  }

  if (error) {
    return <Error />;
  }

  return (
    <Router>
      <ScrollToTopOnRouteChange />
      <Navbar routes={routes} />
      <div className="page-container background">
        <div className="route-container">
          <Routes>
            {routes.map((route, index) => (
              <Route key={index} path={route.path} element={route.element} />
            ))}
          </Routes>
        </div>
        <MusicPlayer />
      </div>
    </Router>
  );
};

export default App;
