import React from "react";
import { useParams } from "react-router-dom";

import { useQuery } from "@apollo/client";
import { media } from "../gql/Query";

import Fade from "react-reveal/Fade";
import { SingleItem } from "../components/SingleItem";

export const Mix = () => {
  const { data } = useQuery(media);
  const { slug } = useParams();
  const mixes = data?.mixes;

  const mix = mixes?.find((mix: any) => mix?.slug === slug);
  const formattedDescription = mix.description.replace("test", "<br>");

  return (
    <div className="single-mix">
      <Fade>
        <SingleItem
          title={mix?.title}
          description={
            <div dangerouslySetInnerHTML={{ __html: formattedDescription }} />
          }
          index={mix?.index}
          backUrl={"/mixes"}
          soundcloud={mix?.soundcloud}
          subtitle={`${mix?.residentName} | ${mix?.date} | ${mix?.genre}`}
          videoUrl={mix?.video && mix?.video?.url ? mix.video.url : ""}
          stillUrl={
            mix?.videoStill && mix?.videoStill.url ? mix.videoStill.url : ""
          }
          audioUrl={mix?.audio && mix?.audio.url ? mix.audio.url : ""}
        />
      </Fade>
    </div>
  );
};

export default Mix;
