import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { media } from "../gql/Query";
import { SingleItem } from "../components/SingleItem";
import Fade from "react-reveal/Fade";

export const Event = () => {
  const { data } = useQuery(media);
  const { name } = useParams();
  const events = data?.events;

  const findEvent = events?.find((event: any) => event.url === name);

  const { title, subtitle, description, image, ticketText, ticketUrl } =
    findEvent;

  const formattedDescription = description.replace("test", "<br>");

  return (
    <Fade>
      <SingleItem
        backUrl={`/events`}
        title={title}
        description={
          <div dangerouslySetInnerHTML={{ __html: formattedDescription }} />
        }
        subtitle={subtitle}
        imageUrl={image.url}
        urlText={ticketText}
        externalUrl={ticketUrl}
      />
    </Fade>
  );
};

export default Event;
