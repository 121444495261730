import { useQuery } from "@apollo/client";
import { media } from "../gql/Query";

import Fade from "react-reveal/Fade";

export const Home = () => {
  const { data } = useQuery(media);
  const homeVideo = data?.homeVideos[0];

  const showVideo = window.innerWidth > 1200;

  return (
    <Fade cascade duration={2000}>
      <div className="home content-container">
        <div className="home-video">
          {showVideo ? (
            <video autoPlay muted loop playsInline>
              <source src={homeVideo?.video.url} type="video/mp4" />
            </video>
          ) : (
            <img src={homeVideo?.videoStill.url} alt="Homepage" />
          )}
        </div>
      </div>
    </Fade>
  );
};

export default Home;
