import React from "react";
import { Link } from "react-router-dom";

interface MixItemProps {
  title?: string;
  description?: string;
  residentName?: string;
  date?: string;
  videoUrl?: string;
  videoStill?: string;
  cardIndex?: number;
  audioUrl?: string;
  url: string;
  genre: string;
}

export const MixItem: React.FC<MixItemProps> = ({
  title,
  description,
  residentName,
  date,
  videoUrl,
  videoStill,
  cardIndex,
  audioUrl,
  url,
  genre,
}) => {
  const showVideo = window.innerWidth > 1200;

  return (
    <div className="mix-container">
      <Link to={url}>
        <div className="card-video">
          {showVideo ? (
            <video autoPlay muted loop playsInline>
              <source src={videoUrl} />
            </video>
          ) : (
            videoStill && <img src={videoStill} alt="Mix video cover" />
          )}
        </div>

        <div className="card-text">
          <div className="top">
            <p>{date}</p>
            <p>{residentName}</p>
          </div>
          <div className="middle">
            <p className="title">{title}</p>
          </div>
          <div className="bottom">
            <p>{genre}</p>
          </div>
        </div>
      </Link>
    </div>
  );
};
