import Fade from "react-reveal/Fade";

export const Error = () => {
  return (
    <div className="loading-error">
      <Fade>
        <h3>Website loading failed, please check your Wi-Fi</h3>
      </Fade>
    </div>
  );
};
