import { createSlice } from "@reduxjs/toolkit";

const mediaSlice = createSlice({
  name: 'media',
  initialState: {
      audioTitle: '',
      audioSource: '',
      isPlaying: false,
      audioRef: undefined,
      selectedCard: null,
      mixes: [],
      mixId: null
  },
  reducers: {
      setAudioTitle: (state, action) => {
          state.audioTitle = action.payload;
      },
      setAudioSource: (state, action) => {
          state.audioSource = action.payload;

      },
      toggleIsPlaying: (state) => {
          state.isPlaying = !state.isPlaying;
      },
      setAudioRef: (state, action) => {
          state.audioRef = action.payload;
      },
      setSelectedCard: (state, action) => {
        state.selectedCard = action.payload
      },
      setMixes: (state, action) => {
        state.mixes = action.payload;
      },
      setMixId: (state, action) => {
        state.mixId = action.payload;
      },
  }
});

export const {
  setAudioTitle,
  setAudioSource,
  toggleIsPlaying,
  setAudioRef,
  setSelectedCard,
  setMixes,
  setMixId
} = mediaSlice.actions;

export default mediaSlice.reducer;
