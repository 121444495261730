import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

// Buttons
import { FaSoundcloud } from "react-icons/fa6";
import { FaGlobeAsia, FaInstagram } from "react-icons/fa";
import { BsArrowLeft, BsPauseFill, BsPlayFill } from "react-icons/bs";

// Redux
import { useDispatch, useSelector } from "react-redux";
import {
  setAudioSource,
  setAudioTitle,
  toggleIsPlaying,
  setAudioRef,
  setMixId,
} from "../store/reducers/mediaReducer";

interface SingleItemDisplayProps {
  title?: string;
  soundcloud?: string;
  instagram?: string;
  subtitle?: string;
  description?: string | JSX.Element;
  imageUrl?: string;
  videoUrl?: string;
  stillUrl?: string;
  backUrl?: string;
  audioUrl?: string;
  website?: string;
  externalUrl?: string;
  urlText?: string;
  index?: string;
  residentNameImage?: string;
}

export const SingleItem: React.FC<SingleItemDisplayProps> = ({
  title,
  soundcloud,
  instagram,
  subtitle,
  description,
  imageUrl,
  videoUrl,
  backUrl,
  audioUrl,
  stillUrl,
  website,
  externalUrl,
  urlText,
  index,
  residentNameImage,
}) => {
  const dispatch = useDispatch();
  const { isPlaying, audioRef, mixId } = useSelector(
    (state: any) => state.media
  );

  const showVideo = window.innerWidth > 1200;

  const handlePlayPause = () => {
    if (index !== mixId) {
      dispatch(setAudioTitle(title));
      dispatch(setAudioSource(audioUrl));
      dispatch(setAudioRef(audioRef));
      dispatch(toggleIsPlaying());
      dispatch(setMixId(mixId));
    } else {
      dispatch(toggleIsPlaying());
    }
  };

  return (
    <div className="single-item content-container">
      <div className="left">
        <div className="text-container">
          {backUrl && (
            <Link to={backUrl} className="back">
              <BsArrowLeft />
            </Link>
          )}
          <div className="title-container">
            {residentNameImage ? (
              <img src={residentNameImage} alt="resident name" />
            ) : (
              <h1 className="name">{title}</h1>
            )}

            <div className="socials">
              {website && (
                <Link to={website} target="_blank">
                  <FaGlobeAsia />
                </Link>
              )}
              {soundcloud && (
                <Link to={soundcloud} target="_blank">
                  <FaSoundcloud />
                </Link>
              )}
              {instagram && (
                <Link to={instagram} target="_blank">
                  <FaInstagram />
                </Link>
              )}
            </div>
          </div>

          <div className="description-container">
            {subtitle && <p className="subtitle">{subtitle}</p>}
            <p>{description}</p>
            {externalUrl && urlText && (
              <Link target="_blank" to={externalUrl}>
                {urlText}
              </Link>
            )}
          </div>
        </div>
      </div>

      <div className="right">
        <div className="media-container">
          {imageUrl && <img src={imageUrl} alt="Item" />}
          {videoUrl && (
            <div className="audio-container">
              <div className="play-pause" onClick={handlePlayPause}>
                {isPlaying && index !== mixId ? (
                  <BsPauseFill className="button" />
                ) : (
                  <BsPlayFill className="button" />
                )}
              </div>
              {showVideo ? (
                <video autoPlay muted loop>
                  <source src={videoUrl} />
                </video>
              ) : (
                <img src={stillUrl} alt="Cover" />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

SingleItem.propTypes = {
  title: PropTypes.string.isRequired,
  soundcloud: PropTypes.string,
  instagram: PropTypes.string,
  subtitle: PropTypes.string,
  description: PropTypes.string.isRequired,
  imageUrl: PropTypes.string,
  videoUrl: PropTypes.string,
  backUrl: PropTypes.string,
  audioUrl: PropTypes.string,
  index: PropTypes.string,
};
