import React from "react";
import Fade from "react-reveal/Fade";
import { PhotoGallery } from "../components/ImageGallery";

export const About = () => {
  return (
    <div className="content-container about-container single-item">
      <Fade>
        <div className="left">
          <div className="text-container">
            <p>
              Live At The BBQ, an ever-evolving music-centered initiative that
              has left a mark on the Dutch music scene since its establishment
              in 2007, has undergone several transformative phases, guided by
              contributors who shaped its mission of amplifying community voices
              through music. Its initial birth place in Bitterzoet is where the
              event first took shape, before going on to operate across multiple
              venues in Amsterdam and beyond.
            </p>
            <p>
              With its latest family members, Live At The BBQ continues the
              journey of cultivating a stronger sense of community, encouraging
              people to come together, share their musical expressions, and
              celebrate their collective unity. We remain committed to promoting
              the often-overlooked talents in our diverse musical landscape,
              fostering cross-cultural connections, and sharing the beauty of
              music that transcends boundaries. Our approach includes hosting
              movie screenings, community gatherings, and club nights, offering
              different avenues for the celebration of sound, artistry, and
              unity.
            </p>
          </div>
        </div>
        <div className="right">
          <div className="media-container">
            <PhotoGallery />
          </div>
        </div>
      </Fade>
    </div>
  );
};

export default About;
