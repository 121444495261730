import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

interface GridItemProps {
  title?: string;
  subtitle?: string;
  url: string;
  imageUrl?: string;
  videoUrl?: string;
  videoStill?: string;
  titleImage?: string;
  residentNameImage?: string;
  classNameImg?: string;
}

export const GridItem: React.FC<GridItemProps> = ({
  title,
  subtitle,
  url,
  imageUrl,
  videoUrl,
  videoStill,
  titleImage,
  residentNameImage,
  classNameImg,
}) => {
  const showVideo = window.innerWidth > 1200;

  return (
    <Link to={url} className="grid-item">
      <div className="overlay">
        <div className={classNameImg === "birdguy" ? "title birdguy" : "title"}>
          <h3>{title}</h3>
          {titleImage && (
            <img src={titleImage} alt="label" className="title-img" />
          )}
          {residentNameImage && <img src={residentNameImage} alt="label" />}

          {subtitle && <p>{subtitle}</p>}
        </div>
      </div>

      <div className="background">
        {imageUrl && <img src={imageUrl} alt="Grid Cover" />}
        {videoUrl && showVideo ? (
          <video autoPlay muted loop playsInline>
            <source src={videoUrl} />
          </video>
        ) : (
          videoStill && <img src={videoStill} alt="Grid Video Cover" />
        )}
      </div>
    </Link>
  );
};

GridItem.propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  imageUrl: PropTypes.string,
  videoUrl: PropTypes.string,
};
