import {gql} from "@apollo/client";

export const media = gql`
   query MyQuery {
    residents(orderBy: index_ASC) {
      residentName
      residentBio
      residentNameImage {
        url
      }
      index
      residentImage {
        url
      }
      instagram
      soundcloud
      website
  }
  galleryImages {
    description
    thumbnail {
      url
    }
    original {
      url
    }
  }
  mixes(orderBy: residentName_ASC)  {
    title
    description
    residentName
    audio {
      url
    }
    video {
      url
    }
    videoStill {
      url
    }
    date
    genre
    slug
    category
    index
  }
  homeVideos {
    video {
      url
    }
    videoStill {
      url
    }
  }
  libraryGrids {
    title
    image {
      url
    }
    titleImage {
      url
    }
  }
  libraryItems {
    title
    description
    subtitle
    url
    urlText
    externalUrl
    image {
      url
    }
    type
  }
  events {
    title
    subtitle
    description
    image {
      url
    }
    url
    ticketUrl
    ticketText
  }
}
`

