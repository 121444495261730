import { useState } from "react";
import { Link } from "react-router-dom";
import Fade from "react-reveal/Fade";

export const LibraryMenu = () => {
  const [activePage, setActivePage] = useState("");
  const navItems = [
    { path: "/library/read", label: "Read" },
    { path: "/library/watch", label: "Watch" },
    { path: "/library/listen", label: "Listen" },
  ];

  return (
    <div className="library-menu">
      {navItems.map((item, index) => (
        <Link
          key={index}
          to={item.path}
          onClick={() => {
            setActivePage(item.label);
          }}
          className={
            item.label === activePage
              ? "library-menu-item active-page"
              : "library-menu-item"
          }
        >
          {item.label}
        </Link>
      ))}
    </div>
  );
};

export default LibraryMenu;
