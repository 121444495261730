import { Link } from "react-router-dom";
import Fade from "react-reveal/Fade";
import { SingleItem } from "../components/SingleItem";
import { FaInstagram } from "react-icons/fa";
import { AiOutlineMail } from "react-icons/ai";
export const Contact = () => {
  const contactInformation = () => {
    return (
      <>
        <Link
          to="https://instagram.com/liveatthebbq.nl"
          target="_blank"
          className="contact-block"
        >
          <FaInstagram />
          <p>liveatthebbq.nl</p>
        </Link>
        <Link
          to="mailto: liveatthebbqnl@gmail.com"
          target="_blank"
          className="contact-block"
        >
          <AiOutlineMail />
          <p>liveatthebbqnl@gmail.com</p>
        </Link>
      </>
    );
  };

  return (
    <div className="contact">
      <Fade>
        <SingleItem
          title="Contact"
          description={contactInformation()}
          imageUrl="https://museumofyouthculture.com/wp-content/uploads/2019/12/Soundsystem15.jpg"
        />
      </Fade>
    </div>
  );
};

export default Contact;
