import { useQuery } from "@apollo/client";
import { media } from "../gql/Query";

import { useParams } from "react-router-dom";

import Fade from "react-reveal/Fade";

import { GridItem } from "../components/GridItem";
import LibraryMenu from "./LibraryMenu";

export const LibraryInner = () => {
  const { data } = useQuery(media);
  const { name } = useParams();

  const filteredItems = data?.libraryItems.filter(
    (item: any) => item.type === name
  );

  return (
    <div className="content-container library">
      <LibraryMenu />
      <Fade>
        <div className="grid">
          {filteredItems?.map((item: any, index: number) => (
            <GridItem
              title={item.title}
              key={index}
              url={item.url}
              imageUrl={item.image.url}
              subtitle={item.subtitle}
            />
          ))}
        </div>
      </Fade>
    </div>
  );
};

export default LibraryInner;
