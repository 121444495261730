import { useQuery } from "@apollo/client";
import { media } from "../gql/Query";
import { GridItem } from "../components/GridItem";
import Fade from "react-reveal/Fade";

export const Events = () => {
  const { data } = useQuery(media);

  return (
    <Fade>
      <div className="content-container grid events library">
        {data?.events.map((item: any, index: number) => (
          <GridItem key={index} url={item.url} imageUrl={item.image.url} />
        ))}
      </div>
    </Fade>
  );
};

export default Events;
