import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { media } from "../gql/Query";
import { SingleItem } from "../components/SingleItem";
import Fade from "react-reveal/Fade";
import LibraryMenu from "./LibraryMenu";

export const LibraryItem = () => {
  const { data } = useQuery(media);
  const { item } = useParams();
  const libraryItems = data?.libraryItems;

  const findItem = libraryItems?.find(
    (libraryItem: any) => libraryItem.url === item
  );

  const { title, subtitle, description, image, type, urlText, externalUrl } =
    findItem;

  return (
    <Fade>
      <SingleItem
        backUrl={`/library/${type}`}
        title={title}
        description={description}
        subtitle={subtitle}
        imageUrl={image.url}
        urlText={urlText}
        externalUrl={externalUrl}
      />
    </Fade>
  );
};

export default LibraryItem;
